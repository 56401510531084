<template>

    <div>
      <div class="card">
        <Button class="p-button-text pi pi-angle-left p-mb-2" @click="$router.go(-1)"> 返回</Button>
        <Panel header="会员企业信息" :toggleable="true">
          <div class="card p-fluid">
            <div class="p-grid p-fluid">
              <div class="p-field p-col-12 p-md-4">
                <label for="name2">企业名称：</label>
                <InputText @blur="change" v-model="from.companiesName" id="name2"></InputText>
                <span class="change" v-if="flag">企业名称为必填项</span>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="email2">统一社会信用代码：</label>
                <InputText @blur="change1" v-model="from.creditCode" id="email2"></InputText>
                <span class="change" v-if="flag1">社会信用代码为必填项</span>
                <span class="change" v-if="flag2"> 输入的格式有误</span>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="name3">企业类型：</label>
                <InputText @blur="change2" v-model="from.companiesType" id="name3" type="text"></InputText>
                <span class="change" v-if="flag3">企业类型为必填项</span>
              </div>
            </div>
            <div class="p-grid p-fluid">
              <div class="p-field p-col-12 p-md-4">
                <label for="email3">注册地址：</label>
                <InputText @blur="change3" v-model="from.enrollAddr" id="email3" type="text"></InputText>
                <span class="change" v-if="flag4">注册地址为必填项</span>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label>注册资本：</label>
                <InputText @blur="change4" v-model="from.registeredCapital"/>
                <span class="change" v-if="flag5">注册资本为必填项</span>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label>成立日期：</label>
                <Calendar onfocus=this.blur() placeholder="选择成立日期" inputId="calendar"
                          v-model="from.establishTime"></Calendar>
                <span class="change" v-if="flag6">成立日期不能为空</span>
              </div>
            </div>

            <div class="p-grid p-fluid ">
              <div class="p-field p-col-12 p-md-4">
                <label>法人代表：</label>
                <InputText @blur="change5" v-model="from.juridicalPerson"></InputText>
                <span class="change" v-if="flag7">法人代表为必填项</span>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label>所属行业：</label>

                <Dropdown @blur="change6"  class="p-ml-3" v-model="from.industry" :options="selectedIndustry" optionLabel="name"
                          placeholder="所属行业" option-value="name" :show-clear="true"/>
                <span class="change" v-if="flag8">企业行业为必填项</span>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="name4">经营范围：</label>
                <InputText @blur="change7" v-model="from.businessScope" id="name4" type="text"></InputText>
                <span class="change" v-if="flag9">企业经营范围为必填项</span>
              </div>
            </div>

            <div class="p-grid p-fluid ">
              <div class="p-field p-col-12">
                <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">公司简介：</label>
                <my-editor :value="from.brief" v-on:input="setContent"></my-editor>
<!--                <Editor @blur="change8" v-model="from.brief" class="p-col-12"-->
<!--                          type="text"></Editor>-->
                <span class="change" v-if="flag10">公司简介为必填项</span>
              </div>
            </div>
            <div class="p-grid">
              <Toast/>
              <div class="p-col-6">
                <label for="name2">营业执照：</label>
                <FileUpload class="p-mt-2" v-model="from.license" name="file" :url=filesUpload @select="selectPic"
                            @upload="onUpload($event,1)" accept="image/*" mode="basic"
                            :maxFileSize="1000000" choose-label="上传营业执照"/>
                <img :src="url+from.license" class="p-mt-2" style="width: 70%;"/>
              </div>
              <div class="p-col-6">
                <label for="name2">上传企业风采照片：</label>
                <FileUpload class="p-mt-2" v-model="from.pic" name="file" :url=filesUpload @select="selectPic"
                            mode="basic"
                            @upload="onUpload($event,2)" accept="image/*"
                            :maxFileSize="1000000" choose-label="上传企业风采照片"/>
                <img :src="url+from.pic" class="p-mt-2" style="width: 70%;"/>
              </div>
            </div>
          </div>
        </Panel>
      </div>

      <div class="card">
        <Panel header="法人信息" :toggleable="true">
          <div class="p-grid">
            <div class="p-field p-col">
              <label>法人姓名：</label>
              <InputText style="margin-right: 5px" @blur="change9" v-model="from.juridicalPersonName"
                         class="p-col-4 p-ml-2"></InputText>
              <span class="change" v-if="flag11">您输入格式有误，请重新输入</span>
            </div>
            <div class="p-field p-col">
              <label>身份证号码：</label>
              <InputText style="margin-right: 5px" @blur="change10" v-model="from.idCardNum"
                         class="p-col-4"></InputText>
              <span class="change" v-if="flag12">您输入格式有误，请重新输入</span>
            </div>
          </div>
          <div class="p-formgrid p-grid">
            <div class="p-field p-col">
              <label>身份证正面：</label>
              <FileUpload v-model="from.idCardFront" name="file" :url=filesUpload @select="selectPic"
                          @upload="onUpload($event,3)" accept="image/*" mode="basic"
                          :maxFileSize="1000000" choose-label="上传身份证正面"/>
              <img :src="url+from.idCardFront" class="p-mt-2" style="width: 70%;"/>
            </div>
            <div class="p-field p-col">
              <label>身份证背面：</label>
              <FileUpload v-model="from.idCardOpposite" name="file" :url=filesUpload @select="selectPic"
                          @upload="onUpload($event,4)" accept="image/*" mode="basic"
                          :maxFileSize="1000000" choose-label="上传身份证背面"/>
              <img :src="url+from.idCardOpposite" class="p-mt-2" style="width: 70%;"/>
            </div>
          </div>
        </Panel>
      </div>
      <div class="card">
        <Panel header="开户信息" :toggleable="true">
          <div class="p-fluid">
            <div class="p-grid p-fluid">
              <div class="p-field p-col">
                <label>开户账号：</label>
                <InputText @blur="change11" v-model="from.accountId" class="p-col-4"></InputText>
                <span class="change" v-if="flag13">您输入格式有误，请重新输入</span>
              </div>
              <div class="p-field p-col">
                <label>银联号：</label>
                <InputText @blur="change12" v-model="from.unionPay" class="p-col-4"></InputText>
                <span class="change" v-if="flag14">您输入格式有误，请重新输入</span>
              </div>
            </div>
            <div class="p-grid p-fluid ">
              <div class="p-field p-col-12 p-md-4 ">
                <label>开户银行：</label>
                <InputText @blur="change13" type="text" v-model="from.bankName"/>
                <span class="change" v-if="flag15">您输入格式有误，请重新输入</span>
              </div>
              <div class="p-field p-col-12 p-md-4 ">
                <label>所属省份：</label>
                <InputText @blur="change14" v-model="from.province"></InputText>
                <span class="change" v-if="flag16">您输入格式有误，请重新输入</span>
              </div>
              <div class="p-field p-col-12 p-md-4 ">
                <label>所属市区：</label>
                <InputText @blur="change15" v-model="from.city"></InputText>
                <span class="change" v-if="flag17">您输入格式有误，请重新输入</span>
              </div>
            </div>

            <div class="p-formgrid p-grid">
              <div class="p-field p-col">
                <label>开户许可证：</label>
                <FileUpload v-model="from.permit" name="file" :url=filesUpload @select="selectPic"
                            @upload="onUpload($event,5)" accept="image/*" mode="basic"
                            :maxFileSize="1000000" choose-label="上传开户许可证"/>
                <img :src="url+from.permit" class="p-mt-2" style="width: 70%;"/>
              </div>
            </div>
          </div>
        </Panel>
      </div>
      <div style="text-align: center" class="p-mt-3">
        <Button @click="submitAccountInfo()" type="submit" label="提交"/>
      </div>
    </div>
  <Toast/>
</template>

<script>
import myEditor from "@/views/backend/myEditor";
export default {
  components:{myEditor},
  data() {
    return {
      flag: false,
      flag1: false,
      flag2: false,
      flag3: false,
      flag4: false,
      flag5: false,
      flag6: false,
      flag7: false,
      flag8: false,
      flag9: false,
      flag10: false,
      flag11: false,
      flag12: false,
      flag13: false,
      flag14: false,
      flag15: false,
      flag16: false,
      flag17: false,
      filesUpload:this.$global_msg.frontendUrl + "/file/uploadOne",
      url:this.$global_msg.imgurl,
      items: [
        {label: '账户信息', to: '/certification'},
        {label: '认证消息', to: '/certification'}
      ],
      selectedIndustry:[],
      from: {
        companiesName: null,
        /*信用代码*/
        creditCode: null,
        companiesType: null,
        /*注册地址*/
        enrollAddr: null,
        /*注册资本*/
        registeredCapital: null,
        /*成立时间*/
        establishTime: null,
        /*法人*/
        juridicalPerson: null,
        /*经营范围*/
        businessScope: null,
        /*营业执照*/
        license: null,

        /*法人姓名*/
        juridicalPersonName: null,
        idCardNum: null,
        /*身份证正面*/
        idCardFront: null,
        /*身份证背面*/
        idCardOpposite: null,

        /*开户许可*/
        accountPermit: null,
        accountId: null,
        /*银联号*/
        unionPay: null,
        /*开户银行*/
        bankName: null,
        /*省份*/
        province: null,
        city: null,

        permit: null,
        pic: null,
        brief: null,
        industry: null,
      },

      countries: [],
      filteredCountries: null,
      toolbarItems: [
        {
          label: 'Save',
          icon: 'pi pi-check'
        },
        {
          label: 'Update',
          icon: 'pi pi-upload'
        },
        {
          label: 'Delete',
          icon: 'pi pi-trash'
        },
        {
          label: 'Home Page',
          icon: 'pi pi-home'
        },
      ],
      cardMenu: [
        {label: 'Save', icon: 'pi pi-fw pi-check'},
        {label: 'Update', icon: 'pi pi-fw pi-refresh'},
        {label: 'Delete', icon: 'pi pi-fw pi-trash'},
      ],
      picUrl1: null,
      picUrl2: null,
      picUrl3: null,
      picUrl4: null,
      picUrl5: null,
    }
  },
  mounted() {
    this.showName()
    this.getIndustry()
    this.judge()
  },
  methods: {
    setContent(val){this.brief = val},
    getIndustry() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'suoshuhangye'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }

        this.selectedIndustry = resList;
      })
    },
    showName() {
      this.$http.get('/company/getOne').then((res) => {
        this.from.companiesName = res.data.name
      })
    },
    change() {
      if (this.from.companiesName == null | this.from.companiesName == "") {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    change1() {
      if (this.from.creditCode == null | this.from.creditCode == "") {
        this.flag1 = true;
      } else {
        if (this.from.creditCode.length == 18) {
          this.flag2 = false
        } else {
          this.flag2 = true
        }
        this.flag1 = false
      }
    },
    change2() {
      if (this.from.companiesType == null | this.from.companiesType == "") {
        this.flag3 = true;
      } else {
        this.flag3 = false;
      }
    },

    change3() {
      if (this.from.enrollAddr == null | this.from.enrollAddr == "") {
        this.flag4 = true;
      } else {
        this.flag4 = false;
      }
    },
    change4() {
      if (this.from.registeredCapital == null | this.from.registeredCapital == "") {
        this.flag5 = true;
      } else {
        this.flag5 = false;
      }
    },
    change5() {
      if (this.from.juridicalPerson == null | this.from.juridicalPerson == "") {
        this.flag7 = true;
      } else {
        this.flag7 = false;
      }
    },
    change6() {
      if (this.from.industry == null | this.from.industry == "") {
        this.flag8 = true;
      } else {
        this.flag8 = false;
      }
    },
    change7() {
      if (this.from.businessScope == null | this.from.businessScope == "") {
        this.flag9 = true;
      } else {
        this.flag9 = false;
      }
    },
    change8() {
      if (this.from.brief == null | this.from.brief == "") {
        this.flag10 = true;
      } else {
        this.flag10 = false;
      }
    },
    change9() {
      if (this.from.juridicalPersonName == null | this.from.juridicalPersonName == "") {
        this.flag11 = true;
      } else {
        if (this.from.juridicalPersonName.length <= 4) {
          this.flag11 = false;
        } else {
          this.flag11 = true;
        }
      }
    },
    change10(e) {
      let b = e.target.value.replace(/[\W]/g, '')
      if (b != e.target.value | b == null | b == "") {
        this.flag12 = true;
      } else {
        if (b.length == 18) {
          this.flag12 = false;
        } else {
          this.flag12 = true;
        }
      }
    },
    change11(e) {
      let b = e.target.value.replace(/[\W]/g, '')
      if (b != e.target.value | b == null | b == "") {
        this.flag13 = true;
      } else {
        this.flag13 = false;
      }
    },
    change12(e) {
      let b = e.target.value.replace(/^ +| +$/g, '')
      if (b != e.target.value | b == null | b == "") {
        this.flag14 = true;
      } else {
        this.flag14 = false;
      }
    },
    change13(e) {
      let b = e.target.value.replace(/[^\u4E00-\u9FA5]/g, '')
      if (b != e.target.value | b == null | b == "") {
        this.flag15 = true;
      } else {
        this.flag15 = false;
      }
    },
    change14(e) {
      let b = e.target.value.replace(/[^\u4E00-\u9FA5]/g, '')
      if (b != e.target.value | b == null | b == "") {
        this.flag16 = true;
      } else {
        this.flag16 = false;
      }
    },
    change15(e) {
      let b = e.target.value.replace(/[^\u4E00-\u9FA5]/g, '')
      if (b != e.target.value | b == null | b == "") {
        this.flag17 = true;
      } else {
        this.flag17 = false;
      }
    },
    judge() {
      this.$http.get('/company/showInformation').then((res) => {
        this.from=res.data
      })
    },
    submitAccountInfo() {
      if (this.from.companiesName == null | this.from.companiesName == ""
          | this.from.creditCode == null | this.from.creditCode == ""
          | this.from.companiesType == null | this.from.companiesType == ""
          | this.from.enrollAddr == null | this.from.enrollAddr == ""
          | this.from.registeredCapital == null | this.from.registeredCapital == ""
          | this.from.juridicalPerson == null | this.from.juridicalPerson == ""
          | this.from.businessScope == null | this.from.businessScope == ""
          | this.from.juridicalPersonName == null | this.from.juridicalPersonName == ""
          | this.from.idCardNum == null | this.from.idCardNum == ""
          | this.from.accountId == null | this.from.accountId == ""
          | this.from.unionPay == null | this.from.unionPay == ""
          | this.from.bankName == null | this.from.bankName == ""
          | this.from.province == null | this.from.province == ""
          | this.from.city == null | this.from.city == "") {
        this.$toast.add({
          severity: 'error', summary: '错误信息', detail: '您有未输入的信息，请重新输入', life: 3000
        })
      } else {
        this.$http.put('/company/addCompanyInformation', this.from).then((res) => {
          res.data
          this.$toast.add({
            severity: 'success', summary: '成功', detail: '企业信息添加成功！', life: 3000
          })
          this.$router.push('/companiesInformation')
        })
      }
    },

    /*    test(e){
          e.originalEvent.path[1].nextElementSibling.removeAttribute('disabled')
          e.originalEvent.path[1].nextElementSibling.classList.remove('p-disabled')
        },*/
    selectPic(event) {
      event.originalEvent.path[3].lastChild.src = event.files[0].objectURL
    },
    onUpload(event,b) {
      console.log(event)
      console.log(b)
      if(b==1){
        this.from.license = event.xhr.response
      }else if(b==2){
        this.from.pic = event.xhr.response
      }else if(b==3){
        this.from.idCardFront = event.xhr.response
      }else if(b==4){
        this.from.idCardOpposite = event.xhr.response
      }else if(b==5){
        this.from.accountPermit = event.xhr.response
      }

      this.$toast.add({severity: 'info', summary: '成功', detail: '文件上传成功', life: 3000});

    },

  }
}
</script>

<style scoped lang="scss">
.change {
  color: red;
}

p {
  line-height: 1.5;
  margin: 0;
}

::v-deep(.p-card .p-card-title) {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0;
}

::v-deep(button) {
  margin-right: .25em;
  margin-left: .25em;
}

::v-deep(.p-splitbutton button) {
  margin-right: 0;
  margin-left: 0;
}

@media screen and (max-width: 960px) {
  .panel-demo {
    .toolbar-demo.card {
      overflow: auto;
    }
  }
}
</style>
